/**
 * NOTE: a change to this value (notificationTypeNameMaxChars) must be accompanied by a database migration to update name characters length
 */
export const notificationTypeNameMaxChars = 100;

/**
 * NOTE: changes to enum values here must be accompanied by a database migration (affects Notification and NotificationTypes DB).
 *
 * @readonly
 * @enum {
  * 'welcomeEmail'|
  * 'listingApproved'|
  * 'auctionEnded'|
  * 'userDetailsChanged'|
  * 'sellerAgreementApproved'|
  * 'sellerAgreementRejected'|
  * 'buyerAgreementApproved'|
  * 'buyerAgreementRejected'|
  * 'bidConfirmed'|
  * 'bankInformationApproved'|
  * 'propertyIsClosed'
 * }
 */
export const NotificationTypeName = {
  /**
   * @readonly
   * @type {'welcomeEmail'}
   */
  WelcomeEmail: 'welcomeEmail',
  /**
   * @readonly
   * @type {'listingApproved'}
   */
  ListingApproved: 'listingApproved',
  /**
   * @readonly
   * @type {'auctionEnded'}
   */
  AuctionEnded: 'auctionEnded',
  /**
   * @readonly
   * @type {'userDetailsChanged'}
   */
  UserDetailsChanged: 'userDetailsChanged',
  /**
   * @readonly
   * @type {'sellerAgreementApproved'}
   */
  SellerAgreementApproved: 'sellerAgreementApproved',
  /**
   * @readonly
   * @type {'sellerAgreementRejected'}
   */
  SellerAgreementRejected: 'sellerAgreementRejected',
  /**
   * @readonly
   * @type {'buyerAgreementApproved'}
   */
  BuyerAgreementApproved: 'buyerAgreementApproved',
  /**
   * @readonly
   * @type {'buyerAgreementRejected'}
   */
  BuyerAgreementRejected: 'buyerAgreementRejected',
  /**
   * @readonly
   * @type {'bidConfirmed'}
   */
  BidConfirmed: 'bidConfirmed',
  /**
   * @readonly
   * @type {'bankInformationApproved'}
   */
  BankInformationApproved: 'bankInformationApproved',
  /**
   * @readonly
   * @type {'propertyIsClosed'}
   */
  PropertyIsClosed: 'propertyIsClosed',
};
