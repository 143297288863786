import React from 'react';
import { MenuItemLink, Responsive, getResources } from 'react-admin';
import { useSelector } from 'react-redux';
import {
  Group, Mail, Room, Stars, Drafts,
} from '@material-ui/icons';
import BusinessIcon from '@material-ui/icons/Business';
import AllDoneIcon from '@material-ui/icons/DoneAll';
import EditIcon from '@material-ui/icons/Edit';
import { getUserData } from 'auth/auth-helpers';
import { isCompliancePerson } from 'now-shared/validation/admin-upsert-user';
import { complianceTodosResourceName } from 'resources/complianceTodos';

const superAdminResources = ['admins'];
const resourcesHiddenFromNonAdmin = [
  'contact-us',
  'buyer-agreement-template/edit',
  'seller-agreement-template/edit',
];
const complianceResources = [complianceTodosResourceName];

const resourceMapping = {
  users: {
    label: 'Users',
    icon: <Group />,
  },
  properties: {
    label: 'Properties',
    icon: <Room />,
  },
  'historical-transactions': {
    label: 'Historical Transactions',
    icon: <Room />,
  },
  bids: {
    label: 'Bids',
    icon: <Stars />,
  },
  admins: {
    label: 'Admins',
    icon: <Group />,
  },
  companies: {
    label: 'Companies',
    icon: <BusinessIcon />,
  },
  [complianceTodosResourceName]: {
    label: 'Compliance Todo',
    icon: <AllDoneIcon />,
  },
  'contact-us': {
    label: 'Contact Us',
    icon: <Mail />,
  },
  'seller-agreements': {
    invisible: true,
  },
  'buyer-agreements': {
    invisible: true,
  },
  'bank-information': {
    invisible: true,
  },
};

export const Menu = ({ onMenuClick, logout }) => {
  const resources = useSelector(getResources);
  const userData = getUserData();

  return (
    <div>
      {resources.map(resource => {
        if (!userData) {
          return null;
        }
        if (resourceMapping[resource.name].invisible) {
          return null;
        }
        if (resourcesHiddenFromNonAdmin.includes(resource) && !userData.isAdmin) {
          return null;
        }
        if (superAdminResources.includes(resource) && !userData.isSuperAdmin) {
          return null;
        }
        if (
          complianceResources.includes(resource)
          && !isCompliancePerson(userData)
          && !userData.isSuperAdmin
        ) {
          return null;
        }

        return (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            leftIcon={resourceMapping[resource.name].icon}
            primaryText={resourceMapping[resource.name].label}
            onClick={onMenuClick}
          />
        );
      })}
      <MenuItemLink
        key="email-communications"
        to="/email-communications"
        leftIcon={<Drafts />}
        primaryText="Email Communications"
        onClick={onMenuClick}
      />
      <MenuItemLink
        key="buyer-agreement-template/edit"
        to="/buyer-agreement-template/edit"
        leftIcon={<EditIcon />}
        primaryText="Edit Buyer Agreement"
        onClick={onMenuClick}
      />
      <MenuItemLink
        key="seller-agreement-template/edit"
        to="/seller-agreement-template/edit"
        leftIcon={<EditIcon />}
        primaryText="Edit Seller Agreement"
        onClick={onMenuClick}
      />
      <Responsive
        small={logout}
        medium={null}
      />
    </div>
  );
};
