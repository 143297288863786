import React, {
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  TextInput,
  List,
  Datagrid,
  Create,
  FunctionField,
  BooleanInput,
  BooleanField,
  NumberField,
  Edit,
  SimpleForm,
  TextField,
  Filter,
  linkToRecord,
  NullableBooleanInput,
  useNotify,
  NumberInput,
  DateInput,
  Labeled,
} from 'react-admin';
import { CustomPagination } from '../components/pagination';
import LinkButton from 'now-frontend-shared/components/LinkButton';
import DeleteButtonWithConfirmation from 'components/deleteButtonWithConfirmation';
import DateFieldWithTimeZone from 'components/dateFieldWithTimeZone';
import { NONOPWELLS_TIME_ZONE } from 'now-shared/helpers/time-helpers';
import { getUserData } from 'auth/auth-helpers';
import {
  disabledWrapper,
  isUiValidationDisabled,
  validateWrapper,
} from 'now-frontend-shared/helpers/ui-validation-toggle';
import {
  isFieldRequired,
} from 'now-shared/validation/company';
import FormSpyForDataProvider from 'components/FormSpyForDataProvider';
import FormDataConsumer from 'components/FormDataConsumer';
import {
  getStandardCreateEditProps,
  getStandardFormProps,
} from 'components/standard-form-props';
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import { SortDirection } from 'now-shared/enums/sort-direction';
import {
  defaultValuesForCreateHistoricalListing,
  hasPermissionToEditField,
  validateHistoricalListing,
} from 'now-shared/validation/historical-listing-validation';

export const HistoricalListingsFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="search" alwaysOn />
    <NullableBooleanInput label="Map Enabled" source="mapEnabled" alwaysOn />
  </Filter>
);

export const HistoricalListingsList = props => (
  <List
    {...props}
    filters={<HistoricalListingsFilter />}
    sort={{
      field: 'id',
      order: SortDirection.Descending,
    }}
    pagination={<CustomPagination />}
    exporter={false}
    bulkActionButtons={<DeleteButtonWithConfirmation {...props} />}
    perPage={50}
  >
    <Datagrid rowClick="edit">
      <NumberField source="id" label="ID" />
      <TextField source="operator" label="Operator" />
      <TextField source="api" label="API" />
      <NumberField source="surfaceHoleLatitude" label="Surface Hole Lat" />
      <NumberField source="surfaceHoleLongitude" label="Surface Hole Long" />
      <NumberField source="bottomHoleLatitude" label="Bottom Hole Lat" />
      <NumberField source="bottomHoleLongitude" label="Surface Hole Long" />
      <NumberField source="totalVerticalDepthInFeet" label="TVD" />
      <TextField source="wellName" label="Well Name" />
      <TextField source="recordedDate" label="Recorded Date" />
      <TextField source="executedDate" label="Executed Date" />
      <TextField source="effectiveDate" label="Effective Date" />
      <NumberField source="wtiSpotPrice" label="WTI Spot Price" />
      <NumberField source="henryHubSpotPrice" label="Henry Hub Spot Price" />
      <TextField source="target" label="Target" />
      <NumberField source="carryPercent" label="Carry%" />
      <TextField source="seller" label="Seller" />
      <TextField source="buyer" label="Buyer" />
      <BooleanField source="mapEnabled" label="Map Enabled" />
    </Datagrid>
  </List>
);

const useHistoricalListingFormItems = ({
  // eslint-disable-next-line no-unused-vars
  formType,
}) => {
  const userData = useMemo(
    () => getUserData(),
    [],
  );

  const hasPermissionToEdit = (values, field) => hasPermissionToEditField(
    userData,
    values,
    field,
  );

  const shouldShowField = useCallback((values, field, form) => {
    const fieldState = form?.getFieldState(field);
    let result;
    if (isUiValidationDisabled()) {
      result = true;
    } else if (fieldState?.dirty) {
      result = true;
    } else if (values[field]) {
      result = true;
    } else {
      result = true;
    }
    return result;
  }, []);

  const shouldDisableField = (values, field, checkCustom) => disabledWrapper(() => {
    let result = false;
    if (!hasPermissionToEdit(values, field)) {
      result = true;
    } else if (checkCustom) {
      result = checkCustom(field, values);
    }
    return result;
  });

  return [
    (
      (field, label) => (
        <FormDataConsumer
          key={field}
        >
          {({ formData, form, ...rest }) => shouldShowField(formData, field, form) && (
            <BooleanInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )}
        </FormDataConsumer>
      )
    )('mapEnabled', 'Map Enabled'),
    (
      (field, label) => (
        <FormDataConsumer
          key={field}
        >
          {({ formData, form, ...rest }) => shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )}
        </FormDataConsumer>
      )
    )('operator', 'Operator'),
    (
      (field, label) => (
        <FormDataConsumer
          key={field}
        >
          {({ formData, form, ...rest }) => shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )}
        </FormDataConsumer>
      )
    )('api', 'API'),
    (
      (field, label) => (
        <FormDataConsumer
          key={field}
        >
          {({ formData, form, ...rest }) => shouldShowField(formData, field, form) && (
            <NumberInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )}
        </FormDataConsumer>
      )
    )('surfaceHoleLatitude', 'Surface Hole Lat'),
    (
      (field, label) => (
        <FormDataConsumer
          key={field}
        >
          {({ formData, form, ...rest }) => shouldShowField(formData, field, form) && (
            <NumberInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )}
        </FormDataConsumer>
      )
    )('surfaceHoleLongitude', 'Surface Hole Long'),
    (
      (field, label) => (
        <FormDataConsumer
          key={field}
        >
          {({ formData, form, ...rest }) => shouldShowField(formData, field, form) && (
            <NumberInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )}
        </FormDataConsumer>
      )
    )('bottomHoleLatitude', 'Bottom Hole Lat'),
    (
      (field, label) => (
        <FormDataConsumer
          key={field}
        >
          {({ formData, form, ...rest }) => shouldShowField(formData, field, form) && (
            <NumberInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )}
        </FormDataConsumer>
      )
    )('bottomHoleLongitude', 'Bottom Hole Long'),
    (
      (field, label) => (
        <FormDataConsumer
          key={field}
        >
          {({ formData, form, ...rest }) => shouldShowField(formData, field, form) && (
            <NumberInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )}
        </FormDataConsumer>
      )
    )('totalVerticalDepthInFeet', 'TVD'),
    (
      (field, label) => (
        <FormDataConsumer
          key={field}
        >
          {({ formData, form, ...rest }) => shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )}
        </FormDataConsumer>
      )
    )('wellName', 'Well Name'),
    (
      (field, label) => (
        <FormDataConsumer
          key={field}
        >
          {({ formData, form, ...rest }) => shouldShowField(formData, field, form) && (
            <DateInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )}
        </FormDataConsumer>
      )
    )('recordedDate', 'Recorded Date'),
    (
      (field, label) => (
        <FormDataConsumer
          key={field}
        >
          {({ formData, form, ...rest }) => shouldShowField(formData, field, form) && (
            <DateInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )}
        </FormDataConsumer>
      )
    )('executedDate', 'Executed Date'),
    (
      (field, label) => (
        <FormDataConsumer
          key={field}
        >
          {({ formData, form, ...rest }) => shouldShowField(formData, field, form) && (
            <DateInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )}
        </FormDataConsumer>
      )
    )('effectiveDate', 'Effective Date'),
    (
      (field, label) => (
        <FormDataConsumer
          key={field}
        >
          {({ formData, form, ...rest }) => shouldShowField(formData, field, form) && (
            <NumberInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )}
        </FormDataConsumer>
      )
    )('wtiSpotPrice', 'WTI Spot Price'),
    (
      (field, label) => (
        <FormDataConsumer
          key={field}
        >
          {({ formData, form, ...rest }) => shouldShowField(formData, field, form) && (
            <NumberInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )}
        </FormDataConsumer>
      )
    )('henryHubSpotPrice', 'Henry Hub Spot Price'),
    (
      (field, label) => (
        <FormDataConsumer
          key={field}
        >
          {({ formData, form, ...rest }) => shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )}
        </FormDataConsumer>
      )
    )('target', 'Target'),
    (
      (field, label) => (
        <FormDataConsumer
          key={field}
        >
          {({ formData, form, ...rest }) => shouldShowField(formData, field, form) && (
            <NumberInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )}
        </FormDataConsumer>
      )
    )('carryPercent', 'Carry%'),
    (
      (field, label) => (
        <FormDataConsumer
          key={field}
        >
          {({ formData, form, ...rest }) => shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )}
        </FormDataConsumer>
      )
    )('seller', 'Seller'),
    (
      (field, label) => (
        <FormDataConsumer
          key={field}
        >
          {({ formData, form, ...rest }) => shouldShowField(formData, field, form) && (
            <TextInput
              {...rest}
              source={field}
              label={label}
              isRequired={isFieldRequired(formData, field)}
              disabled={shouldDisableField(formData, field)}
            />
          )}
        </FormDataConsumer>
      )
    )('buyer', 'Buyer'),
  ];
};

const getSharedFormProps = ({ isForCreate, ...props }) => ({
  ...getStandardFormProps({ isForCreate, ...props }),
  validate: validateWrapper(validateHistoricalListing),
  redirect: '/historical-transactions',
});

const HistoricalListingEditForm = propsAll => {
  const {
    formType,
    ...props
  } = propsAll;

  const notify = useNotify();

  return (
    <>
      <Edit
        {...getStandardCreateEditProps({ notify, ...props })}
      >
        <SimpleForm
          {...getSharedFormProps({ ...props })}
        >
          <FormSpyForDataProvider />
          {useHistoricalListingFormItems(propsAll)}
          <Labeled
            label="Created At"
          >
            <DateFieldWithTimeZone
              source="createdAt"
              showTime
              timeZone={NONOPWELLS_TIME_ZONE}
            />
          </Labeled>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => formData.createdBy && (
            <Labeled
              label="Created By"
            >
              <FunctionField
                render={record => record.createdBy && (
                  <LinkButton
                    label={getUserFullName(record.createdBy)}
                    buttonColor="clearGreen"
                    path={linkToRecord('/users', record.createdBy.id)}
                  />
                )}
                { ...rest }
              />
            </Labeled>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => (formData.updatedAt !== formData.createdAt) && (
            <Labeled label="Updated At">
              <DateFieldWithTimeZone
                { ...rest }
                source="updatedAt"
                showTime
                timeZone={NONOPWELLS_TIME_ZONE}
              />
            </Labeled>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => formData.updatedBy && (
            <Labeled
              label="Updated By"
            >
              <FunctionField
                render={record => record.updatedBy && (
                  <LinkButton
                    label={getUserFullName(record.updatedBy)}
                    buttonColor="clearGreen"
                    path={linkToRecord('/users', record.updatedBy.id)}
                  />
                )}
                { ...rest }
              />
            </Labeled>
            )}
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
    </>
  );
};

const CreateEditComponent = (props => {
  const {
    formType,
    ...propsRest
  } = props;

  const notify = useNotify();

  const formItems = useHistoricalListingFormItems(props);

  return formType === 'create' ? (
    <Create
      {...getStandardCreateEditProps({ notify, isForCreate: true, ...propsRest })}
    >
      <SimpleForm
        {...getSharedFormProps({ isForCreate: true, ...propsRest })}
        initialValues={defaultValuesForCreateHistoricalListing}
      >
        <FormSpyForDataProvider />
        {formItems}
      </SimpleForm>
    </Create>
  ) : (
    <HistoricalListingEditForm
      {...props}
    />
  );
});

CreateEditComponent.propTypes = {
  formType: PropTypes.oneOf(['create', 'edit']).isRequired,
};

export const HistoricalListingCreate = props => (
  <CreateEditComponent formType="create" {...props} />
);

export const HistoricalListingEdit = props => (
  <CreateEditComponent formType="edit" {...props} />
);
