import {
  createFormValidator,
  hasValue,
  maxCharCount,
  minCharCount,
  REQUIRED,
  string,
  trimmed,
  validateIsDateOnly,
  validateIsBoolean,
  validateIsInteger,
  validateIsNegativeNumber,
  validateIsNumberOrNumberString,
  validateIsPositiveNumber,
  validateMaxDecimals,
  validateNumberGreaterThanOrEqualTo,
  validateNumberLessThanOrEqualTo,
  validateNotFutureDate,
} from './validation-rules';
import { validateLatLong } from './validateLatLong';
import { HttpStatus } from '../helpers/https-status';

/**
 * NOTE: updates to this must be accompanied by a database migration
 */
export const operatorMaxCharacters = 200;

export const operatorMinCharacters = 2;

/**
 * NOTE: updates to this must be accompanied by a database migration
 */
export const apiMaxCharacters = 40;

export const apiMinCharacters = 1;

/**
 * NOTE: updates to this must be accompanied by a database migration
 */
export const wellNameMaxCharacters = 200;

export const wellNameMinCharacters = 2;

/**
 * NOTE: updates to this must be accompanied by a database migration
 */
export const targetMaxCharacters = 40;

export const targetMinCharacters = 1;

/**
 * NOTE: updates to this must be accompanied by a database migration
 */
export const buyerMaxCharacters = 200;

export const buyerMinCharacters = 2;

/**
 * NOTE: updates to this must be accompanied by a database migration
 */
export const sellerMaxCharacters = 200;

export const sellerMinCharacters = 2;

export const defaultValuesForCreateHistoricalListing = {
  mapEnabled: true,
};

export const validateHasPermissionToEditField = editor => (
  // eslint-disable-next-line no-unused-vars
  _value,
  // eslint-disable-next-line no-unused-vars
  _values,
  // eslint-disable-next-line no-unused-vars
  _fieldFromValidator,
) => {
  let result;

  if (
    result === undefined
    && !editor?.isSuperAdmin
  ) {
    result = {
      message: 'Only editable by a Super Admin',
      code: HttpStatus.Forbidden,
    };
  }

  return result;
};

export const hasPermissionToEditField = (editor, values, field) => (
  validateHasPermissionToEditField(editor)(undefined, values, field) === undefined
);

// bottom hole latitude

export const validateBottomHoleLatitudeRequired = (bottomHoleLatitude, values) => {
  let result;
  if (!bottomHoleLatitude) {
    if (hasValue(values.bottomHoleLongitude)) {
      result = 'Required when longitude is specified';
    }
  }
  return result;
};

// bottom hole longitude

export const validateBottomHoleLongitudeRequired = (bottomHoleLongitude, values) => {
  let result;
  if (!bottomHoleLongitude) {
    if (hasValue(values.bottomHoleLatitude)) {
      result = 'Required when latitude is specified';
    }
  }
  return result;
};

export const validateFieldIsRequired = (value, values, field) => {
  let result;
  if (!hasValue(value)) {
    if ([
      'surfaceHoleLongitude',
      'surfaceHoleLatitude',
      'mapEnabled',
    ].includes(field)) {
      result = true;
    } else if (field === 'bottomHoleLongitude') {
      result = validateBottomHoleLongitudeRequired(value, values, field);
    } else if (field === 'bottomHoleLatitude') {
      result = validateBottomHoleLatitudeRequired(value, values, field);
    }
  }
  if (result === true) {
    result = REQUIRED;
  }
  return result;
};

export const isFieldRequired = (values, field) => validateFieldIsRequired(undefined, values, field) !== undefined;

/**
 * @type {import('./validation-rules').FieldValidators}
 */
export const historicalListingFieldValidators = {
  operator: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      string,
      trimmed,
      minCharCount(operatorMinCharacters),
      maxCharCount(operatorMaxCharacters),
    ] : [],
  ],
  api: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      string,
      trimmed,
      minCharCount(apiMinCharacters),
      maxCharCount(apiMaxCharacters),
    ] : [],
  ],
  surfaceHoleLatitude: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validateIsNumberOrNumberString,
      validateLatLong('latitude'),
      validateIsPositiveNumber,
    ] : [],
  ],
  surfaceHoleLongitude: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validateIsNumberOrNumberString,
      validateLatLong('longitude'),
      validateIsNegativeNumber,
    ] : [],
  ],
  bottomHoleLatitude: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validateIsNumberOrNumberString,
      validateLatLong('latitude'),
      validateIsPositiveNumber,
    ] : [],
  ],
  bottomHoleLongitude: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validateIsNumberOrNumberString,
      validateLatLong('longitude'),
      validateIsNegativeNumber,
    ] : [],
  ],
  totalVerticalDepthInFeet: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validateIsNumberOrNumberString,
      validateNumberGreaterThanOrEqualTo(0),
      validateIsInteger,
    ] : [],
  ],
  wellName: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      string,
      trimmed,
      minCharCount(wellNameMinCharacters),
      maxCharCount(wellNameMaxCharacters),
    ] : [],
  ],
  recordedDate: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validateIsDateOnly,
      validateNotFutureDate,
    ] : [],
  ],
  executedDate: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validateIsDateOnly,
      validateNotFutureDate,
    ] : [],
  ],
  effectiveDate: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validateIsDateOnly,
      validateNotFutureDate,
    ] : [],
  ],
  wtiSpotPrice: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validateIsNumberOrNumberString,
      validateNumberGreaterThanOrEqualTo(0),
      validateIsInteger,
    ] : [],
  ],
  henryHubSpotPrice: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validateIsNumberOrNumberString,
      validateNumberGreaterThanOrEqualTo(0),
      validateIsInteger,
    ] : [],
  ],
  target: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      string,
      trimmed,
      minCharCount(targetMinCharacters),
      maxCharCount(targetMaxCharacters),
    ] : [],
  ],
  carryPercent: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validateIsNumberOrNumberString,
      validateNumberGreaterThanOrEqualTo(0),
      validateNumberLessThanOrEqualTo(100),
      validateMaxDecimals(2),
    ] : [],
  ],
  seller: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      string,
      trimmed,
      minCharCount(sellerMinCharacters),
      maxCharCount(sellerMaxCharacters),
    ] : [],
  ],
  buyer: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      string,
      trimmed,
      minCharCount(buyerMinCharacters),
      maxCharCount(buyerMaxCharacters),
    ] : [],
  ],
  mapEnabled: (values, value, field) => [
    validateFieldIsRequired,
    ...hasValue(value) || isFieldRequired(values, field) ? [
      validateIsBoolean,
    ] : [],
  ],
};

export const validateHistoricalListing = createFormValidator(historicalListingFieldValidators);
