import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { DataGrid } from '@material-ui/data-grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import { primaryContrast } from 'now-frontend-shared/themes/colors';
import SwitchButton from 'now-frontend-shared/components/Switch';
import { generateFetchOptions } from 'helpers/fetch';
import { NotificationTypeName } from 'now-shared/enums/notifications-type';
import Spinner from 'now-frontend-shared/components/Spinner';
import { toast } from 'react-toastify';

const EmailCommunications = () => {
  const [notifications, setNotifications] = useState(null);
  useEffect(() => {
    const getNotifications = async () => {
      const result = await fetch(`${process.env.REACT_APP_API_URL}/notifications`, generateFetchOptions('GET'));
      const data = await result.json();
      setNotifications(data);
    };
    getNotifications();
  }, []);

  const renderColumns = () => [
    {
      field: 'id',
      headerName: 'Notification',
      minWidth: 300,
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 160,
      sortable: false,
      renderCell: params => <Switch params={params} />,
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => renderColumns(), [notifications]);

  const renderRows = () => [
    {
      id: 'Welcome Email',
      status:
        {
          defaultValue: notifications
            ?.find(n => n?.notificationType?.name === NotificationTypeName.WelcomeEmail)?.active || false,
          type: NotificationTypeName.WelcomeEmail,
        },
    },
    {
      id: 'Listing Approved',
      status:
        {
          defaultValue: notifications
            ?.find(n => n?.notificationType?.name === NotificationTypeName.ListingApproved)?.active || false,
          type: NotificationTypeName.ListingApproved,
        },
    },
    {
      id: 'Auction Ended to Seller',
      status:
        {
          defaultValue: notifications
            ?.find(n => n?.notificationType?.name === NotificationTypeName.AuctionEnded)?.active || false,
          type: NotificationTypeName.AuctionEnded,
        },
    },
    {
      id: 'Auction Ended To Buyer',
      status:
        {
          defaultValue: notifications
            ?.find(n => n?.notificationType?.name === NotificationTypeName.PropertyIsClosed)?.active || false,
          type: NotificationTypeName.PropertyIsClosed,
        },
    },
    {
      id: 'User Details Edited By Admin',
      status:
        {
          defaultValue: notifications
            ?.find(n => n?.notificationType?.name === NotificationTypeName.UserDetailsChanged)?.active || false,
          type: NotificationTypeName.UserDetailsChanged,
        },
    },
    {
      id: 'Seller Agreement Approved',
      status:
        {
          defaultValue: notifications
            ?.find(n => n?.notificationType?.name === NotificationTypeName.SellerAgreementApproved)?.active || false,
          type: NotificationTypeName.SellerAgreementApproved,
        },
    },
    {
      id: 'Seller Agreement Rejected',
      status:
        {
          defaultValue: notifications
            ?.find(n => n?.notificationType?.name === NotificationTypeName.SellerAgreementRejected)?.active || false,
          type: NotificationTypeName.SellerAgreementRejected,
        },
    },
    {
      id: 'Buyer Agreement Approved',
      status:
        {
          defaultValue: notifications
            ?.find(n => n?.notificationType?.name === NotificationTypeName.BuyerAgreementApproved)?.active || false,
          type: NotificationTypeName.BuyerAgreementApproved,
        },
    },
    {
      id: 'Buyer Agreement Rejected',
      status:
        {
          defaultValue: notifications
            ?.find(n => n?.notificationType?.name === NotificationTypeName.BuyerAgreementRejected)?.active || false,
          type: NotificationTypeName.BuyerAgreementRejected,
        },
    },
    {
      id: 'Buyer\'s Bid Confirmed',
      status:
        {
          defaultValue: notifications
            ?.find(n => n?.notificationType?.name === NotificationTypeName.BidConfirmed)?.active || false,
          type: NotificationTypeName.BidConfirmed,
        },
    },
    {
      id: 'Bank Information Approved',
      status:
        {
          defaultValue: notifications
            ?.find(n => n?.notificationType?.name === NotificationTypeName.BankInformationApproved)?.active || false,
          type: NotificationTypeName.BankInformationApproved,
        },
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rows = useMemo(() => renderRows(), [notifications]);

  const Switch = ({ params }) => {
    const [checked, setChecked] = useState(params.value.defaultValue);

    const onUpdate = async option => {
      await fetch(`${process.env.REACT_APP_API_URL}/notification/${params.value.type}`, generateFetchOptions('PUT', { active: option }));
    };
    return (
      <SwitchButton
        checked={checked}
        label={checked ? 'Enabled' : 'Disabled'}
        onChangeNotificationsValue={async ({ value }) => {
          try {
            await onUpdate(value);
            setChecked(value);
          } catch (err) {
            toast.error(`There was an error updating the notification status: ${err.message}`);
          }
        }}
      />
    );
  };

  if (notifications === null) {
    return <Spinner backdrop />;
  }

  return (
    <>
      <CssBaseline />
      <Box
        style={{
          backgroundColor: primaryContrast,
        }}
      >
        <DataGrid
          style={{ margin: '15px 0' }}
          rows={rows}
          columns={columns}
          autoHeight
          autoPageSize
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
        />
      </Box>
    </>
  );
};

EmailCommunications.propTypes = {
  params: PropTypes.shape({
    value: PropTypes.shape({
      defaultValue: PropTypes.bool,
      type: PropTypes.string,
    }),
  }).isRequired,
};

export default EmailCommunications;
