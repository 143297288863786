import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import {
  Filter,
  TextInput,
  List,
  Datagrid,
  FunctionField,
  TextField,
  ChipField,
  SelectInput,
  required,
  minLength,
  maxLength,
  Edit,
  Button,
  TopToolbar,
  FormTab,
  minValue,
  number,
  NumberField,
  TabbedForm,
  NumberInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  Labeled,
  linkToRecord,
  useRedirect,
  addField,
  AutocompleteArrayInput,
  ArrayField,
  SingleFieldList,
  useNotify,
  NullableBooleanInput,
  useRefresh,
  usePermissions,
} from 'react-admin';
import {
  FormSpy,
} from 'react-final-form';
import DeleteButtonWithConfirmation from '../components/deleteButtonWithConfirmation';
import { CustomPagination } from '../components/pagination';
import withCustomData from '../wrappers/withCustomData';
import ReferenceManyList from '../components/referenceManyList';
import { MakeWinnerButton } from '../components/makeWinnerButton';
import { generateFetchOptions } from '../helpers/fetch';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DeleteIcon from '@material-ui/icons/Delete';
import { DocumentButton } from 'now-frontend-shared/components/DocumentButton';
import GoogleMapField from '../components/mapField';
import EsriMapField from '../components/esriMapField';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import UpdateIcon from '@material-ui/icons/Update';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import moment from 'moment';
import LinkButton from 'now-frontend-shared/components/LinkButton';
import DropZone from 'now-frontend-shared/components/DropZone';
import LabelLayout from 'now-frontend-shared/components/inputs/layouts/LabelLayout';
import { AnalyticsBarGraph } from 'now-frontend-shared/components/AnalyticsBarGraph';
import {
  canApproveListing,
  canArchiveListing,
  canCloseListing,
  canRejectListing,
  canReopenListing,
  listingErrors,
  listingHasBeenActive,
  listingHasBeenClosedOrArchived,
  listingIsArchived,
  listingNotYetActive,
  listingNotYetClosedNorArchived,
  minEndTimeForListing,
  minStartTimeForListing,
} from 'now-shared/validation/listing-validation';
import {
  getPreSignedUrls,
  removeAWSDataFile,
  setAllAWSData,
  setAWSData,
  setCurrentProperty,
  setUnloadedFilesExist,
} from 'store/actions/edit-listing-actions';
import { bidStatusIsHistorical } from 'now-shared/validation/bid-validation';
import { getAllCompanies } from 'store/actions/companies-actions';
import {
  toAuctionEventTime,
  NONOPWELLS_TIME_ZONE,
} from 'now-shared/helpers/time-helpers';
import DateInputWithTimeZone from 'components/dateInputWithTimeZone';
import DateFieldWithTimeZone from 'components/dateFieldWithTimeZone';
import {
  ApprovalStatus,
  isRejectionReasonRequired,
} from 'now-shared/validation/approval-status';
import {
  getStandardCreateEditProps,
  getStandardFormProps,
} from 'components/standard-form-props';
import {
  parseServerError, ServerError,
} from 'now-shared/helpers/server-errors';
import {
  doesListingHaveTransactionInProgress,
  isListingTransactionCanceled,
  isListingTransactionComplete,
} from 'now-shared/helpers/escrow-transaction-helpers';
import FormGetter from 'components/FormGetter';
import useRateLimitedFormValidator from 'hooks/useRateLimitedFormValidator';
import { createAdminResourceRejectionReasonValidator } from 'validations/validateRejectionReason';
import { validateWrapper } from 'now-frontend-shared/helpers/ui-validation-toggle';
import { pluralize } from 'now-shared/helpers/text-helpers';
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import { getCompanyName } from 'now-shared/helpers/company-helpers';
import { SortDirection } from 'now-shared/enums/sort-direction';
import { ListingDocumentType } from 'now-shared/enums/listing-document-type';
import { apiBaseUrl, getAuthQueryParamObject } from 'utils/apiMethods';
import {
  computeWellsGrossAfe,
  computeWellsNetAfe,
} from 'now-shared/helpers/listing-helpers';
import FormDataConsumer from 'components/FormDataConsumer';
import { setFormFieldValue } from 'helpers/form-helpers';
import {
  shouldAllowAdminToEditWellNetAfeOnListingThatHasBeenClosedOrArchived,
  shouldAllowAdminToEditWellNameOnListingThatHasBeenClosedOrArchived,
  shouldAllowAdminToEditWellGrossAfeOnListingThatHasBeenClosedOrArchived,
} from 'now-shared/config/admin-settings';
import { clearListingAnalyticsState, getListingAnalytics } from 'store/actions/listingAnalyticsActions';
import { HighLevelStatCard, minSpaceBetweenStatCardsHalf } from 'now-frontend-shared/components/StatCard';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { DataGrid } from '@material-ui/data-grid';
import Spinner from 'now-frontend-shared/components/Spinner';
import { isUsingEsriMaps } from 'now-frontend-shared/features/feature-flags';
import { TransactionTypes, TransactionTypeTitles } from 'now-shared/enums/transaction-types';
import {
  enhanceListingAnalytics,
} from 'now-frontend-shared/utils/helpers';
import { UserRoles } from 'providers';
import { validateLatLong } from 'now-shared/validation/validateLatLong';

const useStyles = makeStyles({
  relativeContainer: {
    position: 'relative',
    flex: 1,
    width: '100%',
  },
  wrapper: {
    height: '100%',
    padding: '40px 75px 70px',

    '@media screen and (max-width: 0px)': {
      padding: '40px 20px',
    },
  },
  container: {
    position: 'relative',
    padding: '10px 46px',

    '@media screen and (max-width: 600px)': {
      padding: '0',
    },
  },
  topPanel: {
    padding: '36px 46px',

    '@media screen and (max-width: 600px)': {
      padding: '30px 0',
    },
  },
  currencyInput: {
    paddingLeft: 25,
  },
  currencyInputWrapper: {
    position: 'relative',
    '&:before': {
      width: 20,
      height: 20,
      display: 'block',
      content: '"$"',
      position: 'absolute',
      top: 23,
      left: 8,
      color: 'rgba(0, 0, 0, 0.87)',
      textAlign: 'center',
    },
  },
  percentInput: {
    paddingRight: 25,
  },
  percentInputWrapper: {
    position: 'relative',
    '&:before': {
      width: 20,
      height: 20,
      display: 'block',
      content: '"%"',
      position: 'absolute',
      top: 23,
      right: 8,
      color: 'rgba(0, 0, 0, 0.87)',
      textAlign: 'center',
    },
  },
});

const urls = {
  statuses: `${process.env.REACT_APP_API_URL}/properties/statuses`,
};

const urlsForEdit = {
  statesWithBasinsCountiesLandingZones: `${process.env.REACT_APP_API_URL}/states`,
};

function compare(a, b) {
  let res = 0;

  if (a.name < b.name) {
    res = -1;
  }

  if (a.name > b.name) {
    res = 1;
  }

  return res;
}

const generateSelectOptions = (array, field) => array.map(item => ({ id: item.id, name: item[field] })).sort(compare);

const PropertiesFilter = props => {
  const statuses = props.statuses || [];

  return (
    <Filter {...props}>
      <TextInput label="Name / Company" source="search" alwaysOn />
      <NullableBooleanInput label="Archived" source="archived" alwaysOn />
      <SelectInput
        label="Status"
        allowEmpty
        emptyValue=""
        source="status"
        alwaysOn
        choices={statuses.map(({ id, title }) => ({ id, name: title }))}
      />
    </Filter>
  );
};

const PropertyEditActions = props => {
  const {
    data,
    form,
  } = props;
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { loaded: permissionsLoaded, permissions } = usePermissions();

  const isSuperAdmin = permissionsLoaded && permissions.includes(UserRoles.SuperAdmin);

  const canReject = data && canRejectListing(data);
  const canApprove = data && canApproveListing(data);
  const canCloseAuction = data && canCloseListing(data);
  const canArchive = data && canArchiveListing(data);
  const canBeReopened = data && canReopenListing(data);

  const onStatusChange = ({ status, rejectionReason = null }) => {
    const options = generateFetchOptions('PUT', { status, rejectionReason });

    fetch(`${process.env.REACT_APP_API_URL}/properties/${data.id}/change-status`, options)
      .then(async response => {
        if (response.status === 200) {
          notify('Status changed');
          refresh();
        } else {
          let message;
          try {
            message = parseServerError(await response.json()).friendlyMessage;
          } catch (error) {
            message = ServerError.Generic;
          }
          notify(message, 'warning');
        }
      });
  };

  const onReopen = () => {
    if (data) {
      if (!isSuperAdmin) {
        notify('Only Super Admins have permission', 'error');
      } else {
        redirect(`/properties/${data.id}/reopen`);
      }
    }
  };

  const generateApproveButtonText = startTime => (
    moment().isAfter(
      toAuctionEventTime(new Date(startTime), 'start')
        .toJSDate(),
    ) ? 'APPROVE (AND OPEN)' : 'APPROVE'
  );

  return (
    <TopToolbar>
      {canApprove && (
        <Button label={generateApproveButtonText(data.startTime)} onClick={() => onStatusChange({ status: 'approved' })}>
          <CheckIcon />
        </Button>
      )}
      {canReject && (
        <Button
          label="REJECT"
          onClick={() => onStatusChange({
            status: ApprovalStatus.Rejected,
            rejectionReason: form.getFieldState('rejectionReason').value,
          })}
          disabled={!form}
        >
          <HighlightOffIcon />
        </Button>
      )}
      {canCloseAuction && (
        <Button
          label="CLOSE AUCTION"
          onClick={() => onStatusChange({ status: 'closed' })}
          data-cy="closeAuctionButton"
        >
          <TimerOffIcon />
        </Button>
      )}
      {canBeReopened && (
        <Button label="REOPEN AS NEW LISTING" onClick={onReopen}>
          <UpdateIcon />
        </Button>
      )}
      {data && doesListingHaveTransactionInProgress(data) && (
        <Button
          label="MARK TRANSACTION COMPLETE"
          onClick={() => onStatusChange({ status: 'transactionComplete' })}
          data-cy="markTransactionCompleteButton"
        >
          <DoneAllIcon />
        </Button>
      )}
      {canArchive && (
        // TODO: [REFACTOR] rename DTO status value to 'archived'
        <Button label="ARCHIVE" onClick={() => onStatusChange({ status: 'deleted' })}>
          <DeleteIcon />
        </Button>
      )}
    </TopToolbar>
  );
};

export const PropertyList = withCustomData(urls)(props => (
  <List
    {...props}
    filters={<PropertiesFilter statuses={props.statuses} />}
    sort={{
      field: 'id',
      order: SortDirection.Descending,
    }}
    pagination={<CustomPagination />}
    exporter={false}
    perPage={50}
    bulkActionButtons={<DeleteButtonWithConfirmation {...props} />}
  >
    <Datagrid rowClick="edit">
      <NumberField source="id" label="Listing ID" />
      <FunctionField render={record => `${record.projectName}, ${record.wellCount} well${pluralize('s', record.wellCount)}`} label="Name" sortBy="projectName" />
      <FunctionField render={record => getUserFullName(record.user)} label="Seller" sortBy="sellerPersonName" />
      <TextField source="user.company.fullLegalCompanyName" label="Company" sortBy="company" />
      <NumberField source="netAfe" label="Net AFE" />
      <DateFieldWithTimeZone
        source="createdAt"
        label="Created"
        timeZone={NONOPWELLS_TIME_ZONE}
      />
      <DateFieldWithTimeZone
        source="archivedAt"
        label="Archival Date"
        timeZone={NONOPWELLS_TIME_ZONE}
      />
      <DateFieldWithTimeZone
        source="startTime"
        label="Start Date"
        timeZone={NONOPWELLS_TIME_ZONE}
      />
      <DateFieldWithTimeZone
        source="endTime"
        label="End Date"
        timeZone={NONOPWELLS_TIME_ZONE}
      />
      <ChipField
        source="status.title"
        label="Status"
        sortBy="status"
      />
      <NumberField source="bidCount" label="Bids" sortable={false} />
      <FunctionField
        label="Commission"
        render={
          record => (record.winningBid
            ? `${record.winningBid.commission}`
            : '-')
        }
      />
    </Datagrid>
  </List>
));

const mapStateToProps = ({ editListing }) => ({
  AWSData: editListing.AWSData,
  AWSDataIsSet: editListing.AWSDataIsSet,
  currentListing: editListing.currentProperty,
  setCurrentListing: setCurrentProperty,
  getPreSignedUrls,
  preSignedUrls: editListing.preSignedUrls,
  removeAWSDataFile,
  setAllAWSData,
  setAWSData,
  setUnloadedFilesExist,
});

export const EditDocuments = compose(
  addField,
  connect(mapStateToProps),
)(props => {
  const {
    AWSData,
    AWSDataIsSet,
    currentListing,
    dispatch,
    editable,
    getPreSignedUrls,
    input,
    label,
    preSignedUrls,
    record,
    removeAWSDataFile,
    setAllAWSData,
    setAWSData,
    setUnloadedFilesExist,
    setCurrentListing,
  } = props;

  false && console.log('props', props);

  const inputDocuments = input.value || [];
  const { onChange } = input;
  const recordDocuments = record.documents || [];
  const listingId = record.id;

  const getDocumentKeys = docs => docs.map(doc => doc.key).join(',');

  const [dropzoneDocumentKeys, setDropzoneDocumentKeys] = useState(undefined);
  const [dropZoneIsProcessingFile, setDropZoneIsProcessingFile] = useState(false);
  const [dropZoneHasFileNotUploaded, setDropZoneHasFileNotUploaded] = useState(false);

  const uploadIsPending = dropZoneIsProcessingFile || dropZoneHasFileNotUploaded;

  useEffect(() => {
    dispatch(setUnloadedFilesExist(uploadIsPending));
  }, [dispatch, uploadIsPending, setUnloadedFilesExist]);

  useEffect(() => {
    false && console.log('changedListing', record);
    dispatch(setAllAWSData(recordDocuments));
    setDropzoneDocumentKeys(getDocumentKeys(recordDocuments));
    dispatch(setCurrentListing(record));
  }, [dispatch, record, recordDocuments, setAllAWSData, setCurrentListing]);

  const dropzoneKey = useMemo(() => {
    const key = `${currentListing ? currentListing.id : 'empty'}-${getDocumentKeys(recordDocuments)}`;
    false && console.log('dropzoneKey', key);
    return key;
  }, [currentListing, recordDocuments]);

  const inputDocumentKeys = useMemo(() => getDocumentKeys(inputDocuments), [inputDocuments]);

  useEffect(() => {
    if (AWSDataIsSet && currentListing && currentListing.id === listingId) {
      setDropzoneDocumentKeys(getDocumentKeys(AWSData));
    }
  }, [
    AWSData,
    AWSDataIsSet,
    currentListing,
    listingId,
  ]);

  useEffect(() => {
    false && console.log('AWSData change', AWSData);
    false && console.log('AWSDataIsSet', AWSDataIsSet);
    false && console.log('currentListing', currentListing);
    false && console.log('listingId', listingId);
    if (!AWSDataIsSet || !currentListing || currentListing.id !== listingId || dropzoneDocumentKeys === undefined) {
      false && console.log('Not yet loaded');
      return;
    }
    false && console.log('inputKeys', inputDocumentKeys);
    false && console.log('dropzoneKeys', dropzoneDocumentKeys);
    if (inputDocumentKeys === dropzoneDocumentKeys) {
      return;
    }
    false && console.log('onChange', AWSData);
    onChange(AWSData);
  }, [
    onChange,
    AWSData,
    AWSDataIsSet,
    currentListing,
    dropzoneDocumentKeys,
    inputDocumentKeys,
    listingId,
  ]);

  return (
    <LabelLayout name="documents" label={label} space="small">
      {editable && currentListing && currentListing.id === listingId && (
        <React.Fragment
          key={dropzoneKey}
        >
          <DropZone
            AWSData={AWSData}
            preSignedUrls={preSignedUrls}
            savedDocuments={recordDocuments}
            setAWSData={setAWSData}
            getPreSignedUrls={getPreSignedUrls}
            removeAWSDataFile={removeAWSDataFile}
            onSetIsProcessingFiles={setDropZoneIsProcessingFile}
            onSetIsSomeFileNotUploaded={setDropZoneHasFileNotUploaded}
            /**
             * TODO: [FEATURE] be able to set the document type using a control
             */
            documentType={ListingDocumentType.NEW_LISTING_OTHER_DOCUMENT}
            placeholderPrompt="Please upload"
            placeholder="Title, AFE, Plats, JOA documents"
            isSimpleView
          />
        </React.Fragment>
      )}
      {!!inputDocuments.length && (
        <DocumentButton
          label="DOWNLOAD ALL (AS ZIP)"
          useDirectLink
          urlGetter={() => queryString.stringifyUrl({
            url: `${apiBaseUrl}/properties/${listingId}/documentsAsArchive`,
            query: getAuthQueryParamObject(),
          }, {
            arrayFormat: 'bracket',
          })}
        />
      )}
      {inputDocuments.map(doc => (
        <DocumentButton
          key={doc.key}
          record={doc}
          useDirectLink
        />
      ))}
    </LabelLayout>
  );
});

const findArrayItemById = (array, id) => array.find(item => item.id === id);

export const PropertyEdit = compose(
  withCustomData(urlsForEdit),
  connect(({ companies, listingAnalytics, editListing }) => ({
    companies: companies.companies,
    isLoadingCompanies: companies.companiesLoading,
    listingAnalytics,
    commonInfo: editListing.currentProperty,
  })),
)(({
  companies,
  isLoadingCompanies,
  statesWithBasinsCountiesLandingZones,
  listingAnalytics: { analytics, analyticsIsLoading },
  commonInfo,
  ...props
}) => {
  const refresh = useRefresh();
  const dispatch = useDispatch();
  const notify = useNotify();
  const [states, setStates] = useState([]);
  const [currentState, setCurrentState] = useState(null);
  const [currentBasin, setCurrentBasin] = useState(null);
  const [currentCounty, setCurrentCounty] = useState(null);
  const [filterByCompany, setFilterByCompany] = React.useState(null);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const classes = useStyles();

  useEffect(() => {
    dispatch(getAllCompanies());
    dispatch(getListingAnalytics({ listingId: props.id }));

    return () => dispatch(clearListingAnalyticsState());
  }, [dispatch, props.id]);

  useEffect(() => {
    if (statesWithBasinsCountiesLandingZones.length && !states.length) {
      setStates(statesWithBasinsCountiesLandingZones);
    }
  }, [statesWithBasinsCountiesLandingZones, states.length]);

  const { dateRange, enhancedAnalytics } = useMemo(
    () => enhanceListingAnalytics({ listing: commonInfo, analytics }),
    [analytics, commonInfo],
  );

  const onLocationChange = useCallback(locationType => e => {
    const locationMapping = {
      state: {
        array: states,
        changeMethod: setCurrentState,
      },
      basin: {
        array: currentState ? currentState.basins : [],
        changeMethod: setCurrentBasin,
      },
      county: {
        array: currentBasin ? currentBasin.counties : [],
        changeMethod: setCurrentCounty,
      },
    };

    const nextLocation = findArrayItemById(locationMapping[locationType].array, e.target.value);
    locationMapping[locationType].changeMethod(nextLocation);

    if (locationType === 'state') {
      setCurrentBasin(null);
      setCurrentCounty(null);
    }

    if (locationType === 'basin') {
      setCurrentCounty(null);
    }
  }, [states, currentBasin, currentState]);

  const { current: validateRejectionReason }
    = useRef(createAdminResourceRejectionReasonValidator('status', canRejectListing));

  const { current: validateListing } = useRef(
    record => {
      const errors = {};
      if (!listingIsArchived(record)) {
        const listingStartDate = toAuctionEventTime(new Date(record.startTime), 'start')
          .toJSDate();

        const listingEndDate = toAuctionEventTime(new Date(record.endTime), 'end')
          .toJSDate();

        if (listingNotYetActive(record)) {
          if (moment(listingStartDate).isBefore(minStartTimeForListing())) {
            errors.startTime = listingErrors.beforeMinStartTime;
          }
        }

        if (listingNotYetClosedNorArchived(record)) {
          if (moment(listingEndDate).isBefore(minEndTimeForListing(listingStartDate))) {
            errors.endTime = listingErrors.beforeMinEndTime;
          } else if (moment(listingEndDate).isBefore(minEndTimeForListing())) {
            errors.endTime = listingErrors.beforeMinEndTimeDefault;
          }
        }
      }

      errors.rejectionReason = validateRejectionReason(record.rejectionReason, record);

      return errors;
    },
  );

  // NOTE: disabled for now, as it seems performance is actually better with it turned off.
  const doRateLimitFormValidation = false;

  const [finalForm, setFinalForm] = useState(undefined);

  const rateLimitedValidate = useRateLimitedFormValidator({
    validate: validateListing,
    form: finalForm,
  });

  const validateForm = doRateLimitFormValidation ? rateLimitedValidate : validateListing;

  return (
    <Edit
      {...getStandardCreateEditProps({ notify, ...props })}
      actions={(
        <PropertyEditActions
          form={finalForm}
        />
      )}
    >
      <TabbedForm
        {...getStandardFormProps({ ...props })}
        validate={validateWrapper(validateForm)}
      >
        <FormTab
          label="Main"
        >
          <FormGetter onForm={setFinalForm} />
          <FormSpy
            subscription={{
              values: true,
            }}
            onChange={() => {
              if (finalForm) {
                const wells = finalForm.getFieldState('wells')?.value;
                setFormFieldValue(
                  finalForm,
                  'netAfe',
                  computeWellsNetAfe(
                    Array.isArray(wells)
                      ? wells.filter(well => !!well)
                      : undefined,
                  ) ?? finalForm.getFieldState('netAfe')?.initial ?? 0,
                );
              }
            }}
          />
          <NumberField source="id" label="Listing ID" />
          <FormDataConsumer>
            {({ formData, form, ...rest }) => (
              <Labeled
                label={formData.reopenedFromPropertyId ? 'Reopened At' : 'Created At'}
              >
                <DateFieldWithTimeZone
                  source="createdAt"
                  showTime
                  timeZone={NONOPWELLS_TIME_ZONE}
                  {...rest}
                />
              </Labeled>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => formData.reopenedBy && (
            <Labeled
              label="Reopened By"
            >
              <FunctionField
                render={record => record.reopenedBy && (
                  <LinkButton
                    label={getUserFullName(record.reopenedBy)}
                    buttonColor="clearGreen"
                    path={linkToRecord('/users', record.reopenedBy.id)}
                  />
                )}
                {...rest}
              />
            </Labeled>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => formData.reopenedFromPropertyId && (
            <Labeled
              label="Reopened From Listing"
            >
              <FunctionField
                render={record => record.reopenedFromPropertyId && (
                  <LinkButton
                    label={record.reopenedFromPropertyId}
                    buttonColor="clearGreen"
                    path={linkToRecord('/properties', record.reopenedFromPropertyId)}
                  />
                )}
                {...rest}
              />
            </Labeled>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => formData.closedAt && (
            <Labeled
              label="Auction Closed At"
            >
              <DateFieldWithTimeZone
                source="closedAt"
                showTime
                timeZone={NONOPWELLS_TIME_ZONE}
                {...rest}
              />
            </Labeled>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => formData.closedBy && (
            <Labeled
              label="Auction Closed By"
            >
              <FunctionField
                render={record => record.closedBy && (
                  <LinkButton
                    label={getUserFullName(record.closedBy)}
                    buttonColor="clearGreen"
                    path={linkToRecord('/users', record.closedBy.id)}
                  />
                )}
                {...rest}
              />
            </Labeled>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => formData.soldAt && (
            <Labeled
              label="Sold At"
            >
              <DateFieldWithTimeZone
                source="soldAt"
                showTime
                timeZone={NONOPWELLS_TIME_ZONE}
                {...rest}
              />
            </Labeled>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => formData.status.title === 'sold' && (
              <Labeled
                label="Transaction Status"
              >
                <FunctionField
                  render={record => (
                    <span data-cy="transactionStatusTitle">
                      {
                        (isListingTransactionComplete(record) && 'Complete')
                        || (isListingTransactionCanceled(record) && 'Canceled')
                        || 'In Progress'
                      }
                    </span>
                  )}
                  {...rest}
                />
              </Labeled>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => formData.status.title === 'sold' && (
              <Labeled
                label="Commission"
              >
                <FunctionField
                  label="Prospective Commission"
                  render={
                    record => (record.winningBid
                      ? `${record.winningBid.commission}`
                      : '-')
                  }
                />
              </Labeled>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => !!formData.transactionCompletedAt && (
              <Labeled
                label="Transaction Completed At"
              >
                <DateFieldWithTimeZone
                  data-cy="transactionCompletedAt"
                  source="transactionCompletedAt"
                  showTime
                  timeZone={NONOPWELLS_TIME_ZONE}
                  {...rest}
                />
              </Labeled>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => listingIsArchived(formData) && (
            <Labeled
              label="Archived At"
            >
              <DateFieldWithTimeZone
                source="archivedAt"
                showTime
                timeZone={NONOPWELLS_TIME_ZONE}
                {...rest}
              />
            </Labeled>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => formData.archivedBy && (
            <Labeled
              label="Archived By"
            >
              <FunctionField
                render={record => record.archivedBy && (
                  <LinkButton
                    label={getUserFullName(record.archivedBy)}
                    buttonColor="clearGreen"
                    path={linkToRecord('/users', record.archivedBy.id)}
                  />
                )}
                {...rest}
              />
            </Labeled>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => formData.reopenedPropertyId && (
            <Labeled
              label="Reopened As Listing"
            >
              <FunctionField
                render={record => record.reopenedPropertyId && (
                  <LinkButton
                    label={record.reopenedPropertyId}
                    buttonColor="clearGreen"
                    path={linkToRecord('/properties', record.reopenedPropertyId)}
                  />
                )}
                {...rest}
              />
            </Labeled>
            )}
          </FormDataConsumer>
          <ChipField source="status.title" label="Status" />
          <FormDataConsumer>
            {({ formData, form, ...rest }) => (
              canRejectListing(formData)
              || formData.status.title === ApprovalStatus.Rejected
            ) && (
              <TextInput
                {...rest}
                source="rejectionReason"
                label="Reason for Rejection"
                isRequired={isRejectionReasonRequired(formData, 'status')}
                disabled={
                  // TODO: [INTEGRITY][REQUIREMENTS] don't allow changing rejection reason once it
                  // has been saved?
                  formData.status.title !== ApprovalStatus.Rejected
                  && !canRejectListing(formData)
                }
                fullWidth
                multiline
                minRows={1}
                maxRows={8}
              />
            )}
          </FormDataConsumer>
          <FunctionField
            label="Seller"
            render={record => (
              <LinkButton
                label={getUserFullName(record.user)}
                buttonColor="clearGreen"
                path={linkToRecord('/users', record.user.id)}
              />
            )}
          />
          <FormDataConsumer>
            {({ formData, form, ...rest }) => (
              <BooleanInput
                source="isAnonymous"
                label="Is Anonymous"
                disabled={listingHasBeenClosedOrArchived(formData)}
                {...rest}
              />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => (
              <TextInput
                source="projectName"
                label="Name"
                validate={[required(), minLength(2), maxLength(200)]}
                disabled={listingHasBeenClosedOrArchived(formData)}
                {...rest}
              />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => (
              <TextInput
                source="operatorName"
                label="Operator"
                validate={[required(), minLength(2), maxLength(200)]}
                disabled={listingHasBeenClosedOrArchived(formData)}
                {...rest}
              />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => {
              const computedGrossWellAfe = computeWellsGrossAfe(
                Array.isArray(formData?.wells)
                  ? formData.wells.filter(well => !!well)
                  : undefined,
              );
              return (
                <NumberInput
                  source="totalWellGrossAfe"
                  label="Total Well Gross AFE Amount"
                  disabled
                  inputProps={{
                    value: computedGrossWellAfe ?? 0,
                  }}
                  {...rest}
                />
              );
            }}
          </FormDataConsumer>
          <NumberInput
            source="netAfe"
            label="Total Well Net AFE"
            disabled
          />
          <FormDataConsumer>
            {({ formData, form, ...rest }) => (
              <SelectInput
                source="transactionType"
                label="Transaction Type"
                allowEmpty
                emptyText="--"
                emptyValue=""
                choices={[
                  { id: 1, value: TransactionTypes.Cash, name: TransactionTypeTitles[TransactionTypes.Cash] },
                  { id: 2, value: TransactionTypes.Carry, name: TransactionTypeTitles[TransactionTypes.Carry] },
                  { id: 3, value: TransactionTypes.Either, name: TransactionTypeTitles[TransactionTypes.Either] },
                ]}
                optionText="name"
                optionValue="value"
                validate={required()}
                disabled={listingIsArchived(formData) || listingHasBeenActive(formData)}
                {...rest}
              />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => ((formData.restrictedCompanies === undefined
                  || isLoadingCompanies
                  || listingHasBeenClosedOrArchived(formData)
            ) ? (
              <Labeled label="Restricted Companies">
                {!formData.restrictedCompanies?.length ? (
                  formData.restrictedCompanies === undefined ? (
                    <div>loading...</div>
                  ) : (
                    <div>-</div>
                  )
                ) : (
                  <ArrayField
                    source="restrictedCompanies"
                    {...rest}
                  >
                    <SingleFieldList
                      linkType={false}
                    >
                      <ChipField
                        source="fullLegalCompanyName"
                      />
                    </SingleFieldList>
                  </ArrayField>
                )}
              </Labeled>
              ) : (
                <AutocompleteArrayInput
                  source="restrictedCompanies"
                  label="Restricted Companies"
                  choices={companies}
                  format={companies => companies?.map(company => company.id)}
                  optionText={getCompanyName}
                  parse={ids => ids?.map(id => ({ id }))}
                  shouldRenderSuggestions={value => value.trim().length > 0}
                  suggestionLimit={10}
                  {...rest}
                />
              ))}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => (
              <Labeled label="Start Time" isRequired>
                <DateInputWithTimeZone
                  disabled={listingIsArchived(formData) || listingHasBeenActive(formData)}
                  source="startTime"
                  minDate={
                    (listingIsArchived(formData) || listingHasBeenActive(formData))
                      ? undefined
                      : minStartTimeForListing()
                  }
                  timeZone={NONOPWELLS_TIME_ZONE}
                  validate={[required()]}
                  {...rest}
                />
              </Labeled>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => (
              <Labeled label="End Time" isRequired>
                <DateInputWithTimeZone
                  disabled={listingHasBeenClosedOrArchived(formData)}
                  source="endTime"
                  minDate={
                    listingHasBeenClosedOrArchived(formData)
                      ? undefined
                      : minEndTimeForListing(formData.startTime)
                  }
                  timeZone={NONOPWELLS_TIME_ZONE}
                  validate={[required()]}
                  {...rest}
                />
              </Labeled>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => {
              // TODO: [BUG] move this into a useEffect block
              if (!currentState) {
                setCurrentState(findArrayItemById(states, formData.state.id));
              }

              return (
                <SelectInput
                  source="state"
                  label="State"
                  format={state => state.id || state}
                  choices={generateSelectOptions(states, 'title')}
                  onChange={onLocationChange('state')}
                  validate={required()}
                  disabled={listingHasBeenClosedOrArchived(formData)}
                  {...rest}
                />
              );
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => {
              // TODO: [BUG] move this into a useEffect block
              if (currentState && !currentBasin) {
                setCurrentBasin(findArrayItemById(currentState.basins, formData.basin.id));
              }

              return (
                <SelectInput
                  source="basin"
                  label="Basin"
                  format={basin => basin.id || basin}
                  choices={currentState ? generateSelectOptions(currentState.basins, 'title') : []}
                  onChange={onLocationChange('basin')}
                  validate={required()}
                  disabled={listingHasBeenClosedOrArchived(formData)}
                  {...rest}
                />
              );
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => {
              // TODO: [BUG] move this into a useEffect block
              if (currentBasin && !currentCounty) {
                setCurrentCounty(findArrayItemById(currentBasin.counties, formData.county.id));
              }

              return (
                <SelectInput
                  source="county"
                  label="County"
                  format={basin => basin.id || basin}
                  choices={currentBasin ? generateSelectOptions(currentBasin.counties, 'title') : []}
                  onChange={onLocationChange('county')}
                  validate={required()}
                  disabled={listingHasBeenClosedOrArchived(formData)}
                  {...rest}
                />
              );
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => (
              isUsingEsriMaps()
                ? (
                  <EsriMapField {...rest} record={formData} />
                )
                : (
                  <GoogleMapField {...rest} record={formData} />
                )
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => {
              const canEdit = listingNotYetClosedNorArchived(formData);
              const requiredMinimumBid = [TransactionTypes.Cash, TransactionTypes.Either].includes(formData.transactionType);
              const requiredMinimumBidCarry = [TransactionTypes.Carry, TransactionTypes.Either].includes(formData.transactionType);
              return (
                <ArrayInput
                  source="wells"
                  label="Wells"
                  {...rest}
                >
                  <SimpleFormIterator
                    disableAdd={!canEdit}
                    disableRemove={!canEdit}
                  >
                    <SelectInput
                      source="landingZone"
                      format={landingZone => ((landingZone && landingZone.id) ? landingZone.id : landingZone)}
                      choices={currentCounty ? generateSelectOptions(currentCounty.landingZones, 'title') : []}
                      label="Landing Zone"
                      validate={required()}
                      disabled={!canEdit}
                    />
                    <NumberInput
                      source="wellAPINumber"
                      label="Well API Number"
                      validate={[number(), minValue(0)]}
                      disabled={!canEdit && !shouldAllowAdminToEditWellGrossAfeOnListingThatHasBeenClosedOrArchived}
                    />
                    <TextInput
                      source="wellName"
                      label="Well Name"
                      validate={[required(), minLength(2), maxLength(200)]}
                      disabled={!canEdit && !shouldAllowAdminToEditWellNameOnListingThatHasBeenClosedOrArchived}
                    />
                    <NumberInput
                      source="rank"
                      label="Rank"
                      validate={[required(), number()]}
                      disabled={!canEdit}
                    />

                    <NumberInput
                      source="wellGrossAfe"
                      label="Well Gross AFE Amount"
                      validate={[required(), number(), minValue(0)]}
                      disabled={!canEdit && !shouldAllowAdminToEditWellGrossAfeOnListingThatHasBeenClosedOrArchived}
                    />
                    <NumberInput
                      source="wellNetAFE"
                      label="Well Net AFE"
                      validate={[required(), number(), minValue(0)]}
                      disabled={!canEdit && !shouldAllowAdminToEditWellNetAfeOnListingThatHasBeenClosedOrArchived}
                    />
                    <NumberInput
                      source="minimumBid"
                      label="Minimum Bid, USD"
                      {...!requiredMinimumBid && {
                        defaultValue: 0,
                      }}
                      validate={[
                        ...requiredMinimumBid ? [
                          required(),
                        ] : [],
                        number(),
                        minValue(0),
                      ]}
                      disabled={!canEdit || !requiredMinimumBid}
                    />
                    <NumberInput
                      source="minimumBidCarry"
                      label="Minimum Bid, Carry"
                      {...!requiredMinimumBidCarry && {
                        defaultValue: 0,
                      }}
                      validate={[
                        ...requiredMinimumBidCarry ? [
                          required(),
                        ] : [],
                        number(),
                        minValue(0),
                      ]}
                      disabled={!canEdit || !requiredMinimumBidCarry}
                    />
                    <NumberInput
                      source="surfaceLatitude"
                      label="Surface Latitude"
                      validate={[required(), validateLatLong('latitude')]}
                      disabled={!canEdit}
                    />
                    <NumberInput
                      source="surfaceLongitude"
                      label="Surface Longitude"
                      validate={[required(), validateLatLong('longitude')]}
                      disabled={!canEdit}
                    />
                    <NumberInput
                      source="bottomLatitude"
                      label="Bottom Latitude"
                      validate={[validateLatLong('latitude')]}
                      disabled={!canEdit}
                    />
                    <NumberInput
                      source="bottomLongitude"
                      label="Bottom Longitude"
                      validate={[validateLatLong('longitude')]}
                      disabled={!canEdit}
                    />
                    <NumberInput
                      source="workingInterestPercentage"
                      label="Working Interest Percentage"
                      validate={[required(), number(), minValue(1)]}
                      disabled={!canEdit}
                    />
                    <NumberInput
                      source="netRevenueInterestNumber"
                      label="Net Revenue Interest Number"
                      validate={[required(), number(), minValue(1)]}
                      disabled={!canEdit}
                    />
                    <NumberInput
                      source="wellTotalVerticalDepth"
                      label="Total Vertical Depth"
                      validate={[required(), number(), minValue(0)]}
                      disabled={!canEdit}
                    />
                    <BooleanInput
                      source="depthConfirmedWithOperator"
                      label="Depth Confirmed With Operator"
                      defaultValue={false}
                      validate={[required()]}
                      disabled={!canEdit}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              );
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, form, ...rest }) => (
              <EditDocuments
                editable={listingNotYetClosedNorArchived(formData)}
                source="documents"
                label="Documents"
                {...rest}
              />
            )}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Bids">
          <ReferenceManyList
            {...props}
            reference="bids"
            apiResource="properties"
          >
            <Datagrid rowClick="edit">
              <FunctionField
                render={record => getUserFullName(record.user)}
                label="Buyer"
                sortable={false}
              />
              <TextField source="user.company.fullLegalCompanyName" label="Company" sortable={false} />
              <FunctionField label="Amount" render={record => record.bidPerWells.map((bidPerWell, index) => (bidPerWell?.amount ? (<div>{`Well ${index + 1}: $${bidPerWell.amount}`}</div>) : '-'))} />
              <FunctionField
                label="Carry"
                render={record => record.bidPerWells.map((bidPerWell, index) => (bidPerWell?.carryPercentage ? (<div>{`Well ${index + 1}: ${bidPerWell.carryPercentage}%`}</div>) : '-'))}
              />
              <FunctionField
                label="Prospective Commission"
                render={
                  record => `$${record.commission}`
                }
              />
              <FunctionField
                hidden
                render={
                  record => (
                    <span
                      data-cy={`bidStatus-${record?.id}`}
                    >
                      {record?.status.title}
                    </span>
                  )
                }
              />
              <ChipField source="status.title" label="Status" sortable={false} />
              <FunctionField
                label="Closing Method"
                render={
                  record => record.property?.closingMethod?.methodName || '-'
                }
              />
              <DateFieldWithTimeZone
                source="createdAt"
                label="Created"
                showTime
                timeZone={NONOPWELLS_TIME_ZONE}
              />
              <DateFieldWithTimeZone
                source="archivedAt"
                label="Archival Date"
                showTime
                timeZone={NONOPWELLS_TIME_ZONE}
              />
              <FunctionField
                render={record => !record.archivedAt && [
                  'closed',
                  'winner selected',
                  // allow 'sold' so the admin can change the winner if needed
                  'sold',
                ].includes(record.status.title) && (
                  <MakeWinnerButton
                    record={record}
                    onSuccess={() => {
                      refresh();
                    }}
                  />
                )}
                label="Make Winner"
              />
              <FunctionField
                render={record => !bidStatusIsHistorical(record) && (
                  <DeleteButtonWithConfirmation
                    basePath="bids"
                    record={record}
                    redirect={false}
                  />
                )}
                label="Delete"
              />
            </Datagrid>
          </ReferenceManyList>
        </FormTab>
        <FormTab label="Analytics">
          <Grid container className={classes.relativeContainer}>
            {analyticsIsLoading && <Spinner backdrop />}
            <Grid container direction="column" className={classes.wrapper}>
              <Grid
                item
                xs={12}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  style={{
                    paddingLeft: 32 - minSpaceBetweenStatCardsHalf,
                    paddingRight: 32 - minSpaceBetweenStatCardsHalf,
                    paddingTop: 32,
                    paddingBottom: 32,
                  }}
                >
                  <HighLevelStatCard
                    data-cy="listingAnalyticsTotalViews"
                    title="Total Views"
                    value={enhancedAnalytics?.totalViews}
                  />
                  <HighLevelStatCard
                    data-cy="listingAnalyticsTotalDownloads"
                    title="Total Downloads"
                    value={enhancedAnalytics?.totalDownloads}
                  />
                  <HighLevelStatCard
                    data-cy="listingAnalyticsTotalBids"
                    title="Total Bids"
                    value={enhancedAnalytics?.totalBids}
                  />
                </Box>
              </Grid>
              <Grid direction="row">
                {!enhancedAnalytics?.companies.length ? (
                  <Grid container justify="center">
                    <span className={classes.message}>No activity</span>
                  </Grid>
                ) : (
                  <>
                    <Grid
                      item
                      xs={12}
                    >
                      <AnalyticsBarGraph
                        filterByCompany={filterByCompany}
                        analytics={enhancedAnalytics}
                        dateRange={dateRange}
                        setFilterByCompany={setFilterByCompany}
                        setSelectionModel={setSelectionModel}
                      />
                    </Grid>
                    <DataGrid
                      style={{ margin: '15px 0' }}
                      rows={enhancedAnalytics.companies}
                      onRowClick={({ id, row }) => setFilterByCompany({ id, name: row.fullLegalCompanyName })}
                      onSelectionModelChange={newSelectionModel => {
                        setSelectionModel(newSelectionModel);
                      }}
                      selectionModel={selectionModel}
                      columns={[
                        {
                          field: 'companyName',
                          headerName: 'COMPANY',
                          flex: 1,
                          valueGetter: ({ row }) => getCompanyName(row),
                        },
                        {
                          field: 'views',
                          headerName: 'VIEWS',
                          flex: 1,
                          valueGetter: ({ row }) => row.views.length,
                        },
                        {
                          field: 'downloads',
                          headerName: 'DOWNLOADS',
                          flex: 1,
                          valueGetter: ({ row }) => row.downloads.length,
                        },
                        {
                          field: 'hasBid',
                          headerName: 'BIDS',
                          flex: 1,
                          valueFormatter: ({ value }) => (value ? 'Yes' : 'No'),
                        },
                      ]}
                      autoHeight
                      autoPageSize
                      disableColumnMenu
                      disableColumnSelector
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
});
