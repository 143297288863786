import { SortDirection } from 'now-shared/enums/sort-direction';
import { stringify } from 'query-string';
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
  sanitizeFetchType,
} from 'react-admin';
import {
  clearUserData,
  getUserData,
} from '../auth/auth-helpers';

const transformResource = (resource, type) => {
  if (
    resource === 'admins'
    && [
      GET_ONE,
      CREATE,
      UPDATE,
      DELETE,
    ].includes(type)
  ) {
    return 'users';
  }

  return resource;
};

const dataProviderFormState = {
  formState: undefined,
};

export const updateDataProviderFormState = formState => {
  if (formState.submitting && !dataProviderFormState.formState) {
    dataProviderFormState.formState = formState;
  } else if (formState.submitFailed || formState.submitSucceeded) {
    dataProviderFormState.formState = undefined;
  }
};

const generatePaginationParams = params => ({
  active: params.filter.active ?? undefined,
  approved: params.filter.approved ?? undefined,
  approvalStatus: params.filter.approvalStatus ?? undefined,
  archived: params.filter.archived ?? undefined,
  page: params.pagination.page || 1,
  limit: params.pagination.perPage || 10,
  mapEnabled: params.filter.mapEnabled ?? undefined,
  order: params.sort.field || 'id',
  search: params.filter.search || undefined,
  sort: params.sort.order || SortDirection.Ascending,
  status: params.filter.status || undefined,
  requiresRepresentativeAction: params.filter.requiresRepresentativeAction ?? undefined,
  requiresSupervisorAction: params.filter.requiresSupervisorAction ?? undefined,
});

const transformRelationsInRequest = body => {
  const manyToOneRelations = {
    state: 'state',
    basin: 'basin',
    county: 'county',
  };
  const oneToManyRelationArrayMapping = {
    wells: 'landingZone',
  };
  const manyToManyRelations = {
    restrictedCompanies: 'restrictedCompanyIds',
  };

  Object.keys(body).forEach(field => {
    const fieldValue = body[field];

    if (Object.keys(manyToOneRelations).includes(field)) {
      const newFieldName = manyToOneRelations[field];
      body[field] = undefined;
      body[newFieldName] = (fieldValue && fieldValue.id) || fieldValue;
    } else if (Object.keys(oneToManyRelationArrayMapping).includes(field)) {
      fieldValue.forEach(relation => {
        const fieldToConvert = oneToManyRelationArrayMapping[field];
        const valueToConvert = relation[fieldToConvert];
        relation[fieldToConvert] = (valueToConvert && valueToConvert.id) || valueToConvert;
      });
    } else if (Object.keys(manyToManyRelations).includes(field)) {
      const newFieldName = manyToManyRelations[field];
      body[field] = undefined;
      body[newFieldName] = fieldValue.map(obj => obj.id);
    }
  });

  return body;
};

const getChangedFormValues = (formData, type) => {
  let data = {};
  if (dataProviderFormState.formState?.dirtyFields) {
    // Only send values that were changed on the frontend

    if (type === CREATE) {
      data = {
        ...dataProviderFormState.formState.initialValues,
      };
    }

    Object.entries(dataProviderFormState.formState.dirtyFields)
      .forEach(
        ([field, isDirty]) => {
          if (isDirty) {
            data[field] = formData[field];
          }
        },
      );
  } else {
    data = {
      ...formData,
    };
  }
  return data;
};

export const DataProvider = (apiUrl = process.env.REACT_APP_API_URL, httpClient = fetchUtils.fetchJson) => {
  const convertDataRequestToHTTP = (type, resource, params, extraQuery) => {
    let url = '';
    const options = {};
    const actualResource = transformResource(resource, type);
    switch (type) {
      case GET_LIST: {
        const query = generatePaginationParams(params);

        if (extraQuery) {
          Object.assign(query, extraQuery);
        }

        url = `${apiUrl}/${actualResource}?${stringify(query)}`;
        break;
      }
      case GET_ONE:
        url = `${apiUrl}/${actualResource}/${params.id}`;
        break;
      case GET_MANY:
        // fall through
      case GET_MANY_REFERENCE: {
        const query = generatePaginationParams(params);
        url = `${apiUrl}/${params.target}/${params.id}/${actualResource}?${stringify(query)}`;
        break;
      }
      case UPDATE: {
        url = `${apiUrl}/${actualResource}/${params.id}`;
        options.method = 'PUT';
        let data = getChangedFormValues(params.data, UPDATE);

        if (resource === 'properties' && data.documents) {
          data.documents = data.documents.map(doc => ({
            id: doc.id,
            filename: doc.filename,
            key: doc.key,
            type: doc.type,
          }));
        }
        options.body = JSON.stringify(transformRelationsInRequest(data));
        break;
      }
      case CREATE: {
        url = `${apiUrl}/${actualResource}`;
        options.method = 'POST';
        const data = getChangedFormValues(params.data, CREATE);
        options.body = JSON.stringify(data);
        break;
      }
      case DELETE: {
        url = `${apiUrl}/${actualResource}/${params.id}`;
        options.method = 'DELETE';
        break;
      }
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { headers, json, status } = response;
    switch (type) {
      case GET_LIST:
        return {
          data: json.items,
          total: json.meta.totalItems,
        };
      case GET_MANY_REFERENCE:
        return {
          data: json.items || json,
          total: json.items ? json.meta.totalItems : json.length,
        };
      case CREATE:
        return { data: { ...params.data, id: json.id } };
      case UPDATE:
        return { data: { ...params.data, id: json.id } };
      case DELETE:
        return { data: { ...params.data, id: params.id, status } };
      default:
        return { data: json, headers };
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  const handleFetchType = async (type, resource, params) => {
    false && console.log(`dataProvider.${type}(${resource})`, params);

    const userId = getUserData()?.id.toString();
    let needsUserRefresh = false;

    if (type === UPDATE_MANY) {
      if (
        ['users', 'admins'].includes(resource)
        && params.ids.includes(userId)
      ) {
        needsUserRefresh = true;
      }
      const responses = await Promise.all(
        params.ids.map(id => httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        })),
      );
      return {
        data: responses.map(response => response.json),
      };
    }

    if (type === DELETE_MANY) {
      if (
        ['users', 'admins'].includes(resource)
        && params.ids.includes(userId)
      ) {
        needsUserRefresh = true;
      }
      const responses = await Promise.all(
        params.ids.map(id => httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
        })),
      );
      return {
        data: responses.map(response => response.json),
      };
    }

    if (
      ['users', 'admins'].includes(resource)
      && type === UPDATE
      && params.id === userId
    ) {
      needsUserRefresh = true;
    }

    const { url, options } = convertDataRequestToHTTP(
      type,
      resource,
      params,
    );

    const response = await httpClient(url, options);

    try {
      if (needsUserRefresh) {
        // invalidate user data so it will be refreshed next server call
        clearUserData({ onlyUserData: true });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error refreshing user data');
      // eslint-disable-next-line no-console
      console.error(err);
    }

    return convertHTTPResponse(response, type, resource, params);
  };

  // Convert the react-admin v2 style of data provider to the v3 style

  const dataProvider = {};

  const allFetchTypes = [
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
  ];

  allFetchTypes.forEach(fetchType => {
    const sanitizedFetchType = sanitizeFetchType(fetchType);
    dataProvider[sanitizedFetchType] = (resource, params) => handleFetchType(fetchType, resource, params);
  });

  return dataProvider;
};
