import React from 'react';
import {
  List,
  Datagrid,
  FunctionField,
  ChipField,
  NumberField,
  TextField,
  Filter,
  SelectInput,
  Edit,
  SimpleForm,
  FormDataConsumer,
  Labeled,
  linkToRecord,
  useRefresh,
} from 'react-admin';
import DeleteButtonWithConfirmation from '../components/deleteButtonWithConfirmation';
import { ResourceToolbar } from '../components/resourceToolbar';
import { CustomPagination } from '../components/pagination';
import withCustomData from '../wrappers/withCustomData';
import { MakeWinnerButton } from '../components/makeWinnerButton';
import { bidStatusIsHistorical } from 'now-shared/validation/bid-validation';
import LinkButton from 'now-frontend-shared/components/LinkButton';
import DateFieldWithTimeZone from 'components/dateFieldWithTimeZone';
import { NONOPWELLS_TIME_ZONE } from 'now-shared/helpers/time-helpers';
import { getUserFullName } from 'now-shared/helpers/user-helpers';
import { getCompanyName } from 'now-shared/helpers/company-helpers';
import { BooleanString, BooleanStringTitle } from 'now-shared/enums/boolean-string';
import { SortDirection } from 'now-shared/enums/sort-direction';

const urls = {
  statuses: `${process.env.REACT_APP_API_URL}/bids/statuses`,
};

const archivedOptions = [
  {
    id: BooleanString.True,
    name: BooleanStringTitle[BooleanString.True],
  },
  {
    id: BooleanString.False,
    name: BooleanStringTitle[BooleanString.False],
  },
];

const BidsFilter = props => {
  const statuses = props.statuses || [];

  return (
    <Filter {...props}>
      <SelectInput
        label="Archived"
        allowEmpty
        emptyValue=""
        source="archived"
        alwaysOn
        choices={archivedOptions}
      />
      <SelectInput
        label="Status"
        allowEmpty
        emptyValue=""
        source="status"
        alwaysOn
        choices={statuses.map(({ id, title }) => ({ id, name: title }))}
      />
    </Filter>
  );
};

export const BidList = withCustomData(urls)(props => (
  <List
    {...props}
    pagination={<CustomPagination />}
    filters={<BidsFilter statuses={props.statuses} />}
    sort={{
      field: 'id',
      order: SortDirection.Descending,
    }}
    exporter={false}
    bulkActionButtons={<DeleteButtonWithConfirmation {...props} />}
    perPage={50}
  >
    <Datagrid
      rowClick="edit"
      sx={{
        '& .column-undefined': { backgroundColor: '#fee' },
      }}
    >
      <NumberField source="id" label="ID" />
      <NumberField source="property.id" label="Listing ID" sortable={false} />
      <TextField source="property.projectName" label="Property" sortable={false} />
      <FunctionField render={record => getUserFullName(record.user)} label="Buyer" sortBy="firstName" />
      <TextField source="user.company.fullLegalCompanyName" label="Company" sortBy="company" />
      <FunctionField label="Amount" render={record => record.bidPerWells?.map((bidPerWell, index) => (bidPerWell?.amount ? (<div style={{ width: '125px' }}>{`Well ${index + 1}: $${bidPerWell.amount}`}</div>) : '-'))} />
      <FunctionField label="Carry" render={record => record.bidPerWells?.map((bidPerWell, index) => (bidPerWell?.carryPercentage ? (<div style={{ width: '125px' }}>{`Well ${index + 1}: ${bidPerWell.carryPercentage}%`}</div>) : '-'))} />
      <FunctionField
        label="Prospective Commission"
        render={
          record => `${record.commission}`
        }
      />
      <DateFieldWithTimeZone
        source="createdAt"
        label="Bid Date"
        showTime
        timeZone={NONOPWELLS_TIME_ZONE}
      />
      <DateFieldWithTimeZone
        source="archivedAt"
        label="Archival Date"
        showTime
        timeZone={NONOPWELLS_TIME_ZONE}
      />
      <DateFieldWithTimeZone
        source="property.startTime"
        label="Date Start"
        timeZone={NONOPWELLS_TIME_ZONE}
        sortable={false}
      />
      <DateFieldWithTimeZone
        source="property.endTime"
        label="Date End"
        timeZone={NONOPWELLS_TIME_ZONE}
        sortable={false}
      />
      <ChipField source="status.title" label="Status" sortable={false} />
    </Datagrid>
  </List>
));

export const BidEdit = props => {
  const refresh = useRefresh();
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<ResourceToolbar {...props} deletingConfirmation />}>
        <NumberField source="id" label="ID" />
        <FunctionField
          label="Listing"
          render={record => (
            <LinkButton
              // TODO: [UX] truncate overflowing label with ellipses or make the button bigger
              label={record.property.projectName}
              buttonColor="clearGreen"
              path={linkToRecord('/properties', record.property.id)}
            />
          )}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => !!formData.archivedAt && (
          <Labeled
            label="Archived At"
          >
            <DateFieldWithTimeZone
              source="archivedAt"
              showTime
              timeZone={NONOPWELLS_TIME_ZONE}
              {...rest}
            />
          </Labeled>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => formData.archivedBy && (
          <Labeled
            label="Archived By"
          >
            <FunctionField
              render={record => record.archivedBy && (
                <LinkButton
                  label={getUserFullName(record.archivedBy)}
                  buttonColor="clearGreen"
                  path={linkToRecord('/users', record.archivedBy.id)}
                />
              )}
              {...rest}
            />
          </Labeled>
          )}
        </FormDataConsumer>
        <FunctionField
          label="Buyer"
          render={record => (
            <LinkButton
              label={getUserFullName(record.user)}
              buttonColor="clearGreen"
              path={linkToRecord('/users', record.user.id)}
            />
          )}
        />
        <FunctionField
          label="Company"
          render={record => (
            <LinkButton
              label={getCompanyName(record.user.company)}
              buttonColor="clearGreen"
              path={linkToRecord('/companies', record.user.company.id)}
            />
          )}
        />
        <ChipField source="status.title" label="Status" />
        <FunctionField label="Amount" render={
          record => record.bidsByWell?.map(
            (bidPerWell, index) => (bidPerWell?.amount ? (<div style={{ width: '125px' }}>{`Well ${index + 1}: $${bidPerWell.amount}`}</div>) : '-')
            )
          } />
        <FunctionField label="Carry Amount" render={
          record => record.bidsByWell?.map(
            (bidPerWell, index) => (bidPerWell?.carryPercentage ? (<div style={{ width: '125px' }}>{`Well ${index + 1}: ${bidPerWell.carryPercentage}%`}</div>) : '-')
            )
          } />
        <FormDataConsumer>
          {({ formData, ...rest }) => !formData.archivedAt && (
            <FunctionField
              {...rest}
              render={record => !record.archivedAt && [
                'closed',
                'winner selected',
                // allow 'sold' so the admin can change the winner if needed
                'sold',
              ].includes(record.status.title) && (
                <MakeWinnerButton
                  record={record}
                  onSuccess={() => {
                    refresh();
                  }}
                />
              )}
            />
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};
